<template>
  <div class="header">
    <div class="logo"><span class="yellow-color">蚁合云</span>商户管理</div>
    <ul class="secondary">
      <li v-for=" item in secondaryList" :key="item.name" :class="{current:current===item.name}" @click="doNav(item)">
        {{ item.title }}
      </li>
    </ul>
    <div class="user-info" v-if="user">
      <span class="text-hover">{{ user.nickname }}</span>
      <i @click="doLogout" class="fa fa-sign-out margin-left text-hover"></i>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import common from "../../api/common";

export default {
  name: "Header",
  computed: {
    ...mapState(['user'])
  },
  data() {
    return {
      secondaryList: [],
      current: ''
    }
  },
  methods: {
    doLogout() {
      this.$Confirm("确认退出系统?", '温馨提示~').then(() => {
        common.logout().then(({data}) => {
          localStorage.removeItem("token");
          window.location.replace('/');
        });
      });
    },
    doNav(item) {
      if (this.$route.name !== item.name) {
        this.current = item.name;
        this.$router.push({name: item.name});
      }
    }
  },
  watch: {
    '$route'(nav) {
      let matched = nav.matched[0];
      if (matched) {
        let rootDef = this.$router.options.routes.find(r => r.name === matched.name);
        if (rootDef.children) {
          this.secondaryList = rootDef.children.map(val => {
            return {
              name: val.name,
              title: val.meta.title
            };
          });
        } else {
          this.secondaryList = [];
        }
      }
      this.current = nav.name;
    }
  }
}
</script>