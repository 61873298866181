import utils from 'hey-utils';

const rclass = /[\t\r\n\f]/g;

export default utils.extend({}, utils, {
  getClass(elem) {
    return (elem.getAttribute && elem.getAttribute('class')) || '';
  },
  hasClass(elem, selector) {
    let className;
    className = ` ${selector} `;
    if (elem.nodeType === 1 && (` ${this.getClass(elem)} `)
      .replace(rclass, ' ')
      .indexOf(className) > -1) {
      return true;
    }

    return false;
  },
  copyObj(target, original) {
    if (original && target) {
      //解除obs
      original = JSON.parse(JSON.stringify(original));
      Object.keys(target).forEach(key => {
        target[key] = original[key];
      });
    }
  }
});
