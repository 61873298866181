import Vue from 'vue'
import App from './App.vue'
import Login from './Login.vue'
import router from './router'
import store from './store'
import common from "./api/common";
import './js/config/dict';
import './styles/app.less'
import 'font-awesome/css/font-awesome.css'

Vue.use(HeyUI);
common.init().then(({data}) => {
  store.commit("updateUser", data);
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}).catch(() => {
  new Vue({
    render: h => h(Login)
  }).$mount('#app')
});


