<template>
  <div id="VLives">
    <Header></Header>
    <NavMenu></NavMenu>
    <PageContent></PageContent>
    <Footer/>
  </div>
</template>
<script>
import Header from "./components/layout/Header";
import NavMenu from "./components/layout/NavMenu";
import PageContent from "./components/layout/PageContent";
import Footer from "./components/layout/Footer";

export default {
  components: {Footer, PageContent, NavMenu, Header},
  watch: {
    '$route'(route) {
      let title = '蚁合云商户管理平台'
      if (route.meta.title) {
        title = route.meta.title + ' - ' + title;
      }
      window.document.title = title;
    }
  },
}
</script>