/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2019 soho team All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2019年11月19日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export default {
  login(username, password) {
    let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    return Ajax.post('/login', formData);
  },
  logout() {
    return Ajax.get('/logout');
  },
  init() {
    return Ajax.get('/init');
  },
  callBack(code) {
    return Ajax.get('/callBack?code=' + code);
  }
}
