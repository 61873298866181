import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Secondary from '../views/Secondary.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/business',
    name: 'BusinessCenter',
    meta: {
      title: '商户中心'
    },
    redirect: {name: 'Merchant'},
    component: Secondary,
    children: [
      {
        path: 'merchant',
        name: 'Merchant',
        meta: {
          title: '商户'
        },
        component: () => import(/* webpackChunkName: "Merchant" */ '../views/business/Merchant.vue')
      }
    ]
  },
  {
    path: '/member',
    name: 'MemberManagement',
    meta: {
      title: '会员管理'
    },
    redirect: {name: 'Member'},
    component: Secondary,
    children: [
      {
        path: 'member',
        name: 'Member',
        meta: {
          title: '会员列表'
        },
        component: () => import(/* webpackChunkName: "Administrator" */ '../views/member/Member')
      }
    ]
  },
  {
    path: '/reports',
    name: 'OperatingReports',
    meta: {
      title: '运营报表'
    },
    component: Secondary
  },
  {
    path: '/system',
    name: 'SystemConfiguration',
    meta: {
      title: '系统配置'
    },
    redirect: {name: 'Administrator'},
    component: Secondary,
    children: [
      {
        path: 'administrator',
        name: 'Administrator',
        meta: {
          title: '管理员列表'
        },
        component: () => import(/* webpackChunkName: "Administrator" */ '../views/system/Administrator.vue')
      },
      {
        path: 'configuration',
        name: 'Configuration',
        meta: {
          title: '系统配置'
        },
        component: () => import(/* webpackChunkName: "Configuration" */ '../views/system/Configuration.vue')
      },
      {
        path: 'agent',
        name: 'agent',
        meta: {
          title: '代理商'
        },
        component: () => import(/* webpackChunkName: "Configuration" */ '../views/system/Agent')
      }

    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
