<template>
  <router-view></router-view>
</template>

<script>
/**
 * 二级页面跳转
 */
export default {
  name: "Secondary"
}
</script>

<style scoped>

</style>
