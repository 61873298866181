<template>
  <div class="nav-menu">
    <div class="user" v-if="user">
      <img class="avator" src="../../assets/default.png" alt="头像">
      <div class="info">
        <div class="text-center">{{ user.nickname }}</div>
        <div class="text-center yellow-color ">{{ user.roleType }}</div>
      </div>
    </div>
    <ListView :datas="menus" v-model="current"></ListView>
  </div>
</template>

<script>
import {mapState} from "vuex";

const menus = [
  {
    id: "Home",
    name: "首页",
  },
  {
    id: "BusinessCenter",
    name: "商户中心",
  },
  {
    id: "MemberManagement",
    name: "会员管理",
  },
  {
    id: "OperatingReports",
    name: "运营报表",
  },
  {
    id: "SystemConfiguration",
    name: "系统配置",
  }
];

export default {
  name: "NavMenu",
  computed: {
    ...mapState(['user'])
  },
  data() {
    return {
      current: null,
      menus
    }
  },
  watch: {
    current(item, old) {
      if (old) {
        this.$router.push({name: item.id});
      }
    }
  },
  mounted() {
    setTimeout(() => {
      let matched = this.$route.matched[0];
      matched && (this.current = {
        id: matched.name,
        name: matched.meta.title,
      });
    }, 1000)
  }
}
</script>
