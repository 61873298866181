<template>
  <div class="footer">
    Copyright © 2020 湖南知向科技信息有限公司
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>
