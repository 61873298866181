/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2019 soho team All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : 字典</li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2019年08月02日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import HeyUI from 'heyui-gson';

HeyUI.initDict({
  state: [{key: true, title: '启用'}, {key: false, title: '禁用'}],
  show: [{key: true, title: '显示'}, {key: false, title: '隐藏'}],
  enable: [{key: true, title: '是'}, {key: false, title: '否'}],
  roleType: {"ADMIN": '管理员', "OPERATOR": '操作员', "AGENT": '代理商'},
  storeCategory: ["丽人/美发", "超市/便利店", "休闲/玩乐", "汽车美容", "美食", "医疗/牙科", "生活服务", "学习培训", "医学美容", "结婚/摄影", "亲子/乐园", "健身/运动"],
});
