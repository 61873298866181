<template>
  <div class="login">
    <div class="box">
      <div class="cover"></div>
      <div class="flex1 form">
        <h1 class="text-center title">
          <span class="primary-color">蚁合云</span>商户管理平台
        </h1>
        <Form mode="block" :model="form" :rules="validationRules" ref="form">
          <FormItem label="用户名" prop="username">
            <input type="text" v-model="form.username">
          </FormItem>
          <FormItem label="密码" prop="password">
            <input @keyup.enter="doLogin" type="password" v-model="form.password">
          </FormItem>
        </Form>
        <Button :loading="isLoading" @click="doLogin" block color="primary" size="l">登 录</Button>
        <div class="copyright">
          Copyright © 2020 湖南知向科技信息有限公司
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "./api/common";

export default {
  name: "Login",
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      validationRules: {
        required: ['username', 'password']
      },
      isLoading: false
    }
  },
  methods: {
    doLogin() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.isLoading = true;
        common.login(this.form.username, this.form.password).then(({data}) => {
          this.$Message.success('登录成功~');
          window.location.replace("/");
        }).finally(() => {
          this.isLoading = false;
        });
      }
    }
  }
}
</script>

<style scoped lang="less">
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    background-image: url("/login-bg.jpg");
    background-size: cover;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; /*-1 可以当背景*/
    filter: grayscale(70%) brightness(0.6);
  }

  .box {
    width: 650px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    display: flex;

    .cover {
      width: 320px;
      border-radius: 10px 0 0 10px;
      background-image: url("./assets/login-cover.png");
      background-size: cover;
      background-position: center;
      border-right: 1px solid #dfdfdf;
    }

    .form {
      padding: 30px;

      .title {
        margin-bottom: 20px;
      }

      input {
        height: 40px;
        background: rgba(255, 255, 255, 0.8);
      }
    }

    .copyright {
      font-size: 12px;
      text-align: center;
      margin: 30px 0 0 0;
      color: #8b8c8d;
    }
  }
}
</style>